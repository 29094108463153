@import "variables";
body {
  margin: 0;
  padding: 0;
  background: #f1f2f3;
  font-family: $ibmplexRegular;
  .licence-description{
    display: flex-centered;
    align-items: center;
    margin:40px 0px 30px 0px;
  }
  .application-card {
    background: #7d7561;
    padding: 20px;
    border-radius: 4px;
    height: 200px;
    margin: 8px;
    position: relative;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    .application-info {
      margin-bottom: 20px;
      h3 {
        margin-bottom: 20px;
        img {
          margin-right: 15px;
        }
        span {
          text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
          @include elementStyles(
            $size: 20px,
            $color: #ffffff,
            $fontFamily: $ibmplexMedium
          );
        }
      }
      p {
        @include elementStyles(
          $size: 16px,
          $color: #ffffff,
          $fontFamily: $ibmplexRegular
        );
      }
    }
    button {
      position: absolute;
      left: 20px;
       bottom: 1px;
      @include elementStyles(
        $size: 20px,
        $color: #ffffff,
        $fontFamily: $ibmplexMedium
      );
    }
    .restrict-access {
      font-size: 13px;
      color: #fff03b;
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
  .file-upload-note {
    position: absolute;
    background: #f9f5c6;
    font-size: 11px;
    padding: 5px;
    right: 3px;
    text-align: center;
    top: 2px;
    left: 3px;
  }
  .ck.ck-content ul,
  .ck.ck-content ul li {
    list-style-type: inherit;
  }

  .ck.ck-content ul {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .text-wrapping {
    word-wrap: break-word;
  }
  .ck-editor__editable {
    min-height: 200px !important;
  }
  .error-msg {
    @include elementStyles(
      $size: 12px,
      $color: #ff0000,
      $fontFamily: $ibmplexRegular
    );
  }
  .for-label {
    @include elementStyles(
      $size: 16px,
      $color: rgba(0, 0, 0, 0.54),
      $fontFamily: $ibmplexRegular
    );
    margin-bottom: 6px;
    display: inline-block;
  }
  .list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
    .list-inline-item-solutions{
      float: right;
    }
  }
  .list-inline {
    list-style: none;
    display: inline-block;
    li.list-inline-item {
      display: inline-block;
      float: left;
    }
  }
  .flex-centered {
    display: flex;
    align-items: center;
  }
  .flex-not-centered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before,
    &:after {
      width: 0;
    }
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .page-description {
    .page-title {
      @include elementStyles(
        $size: 18px,
        $color: #bd10e0,
        $fontFamily: $ibmplexRegular
      );
      margin-bottom: 4px;
    }
    .page-para {
      @include elementStyles(
        $size: 14px,
        $color: #838383,
        $fontFamily: $ibmplexRegular
      );
    }
  }
  .form-status-launched,
  .form-status-inprogress,
  .status2 {
    background-color: #9b9a9b;
  }

  .form-status-draft,
  .status3 {
    background-color: #2c6fb2;
  }

  .form-status-in-review,
  .status5 {
    background-color: #ecd910;
  }

  .form-status-approved,
  .status6 {
    background-color: #89d153;
  }

  .form-status-published,
  .status7 {
    background-color: #396a07;
  }
  .status-label {
    width: 100px;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 3px 0;
    font-size: 10px;
    font-family: $ibmplexSemiBold;
    &.green {
      background-image: linear-gradient(90deg, #beed87, #d2e9b6 95%);
      color: #5ec264;
    }
    &.blue {
      background: #89bfff;
      color: #1a3b62;
      border: 1px solid #459bff;
    }
    &.yellow {
    }
    &.red {
    }
    &.status-1 {
      background-image: linear-gradient(90deg, #beed87, #d2e9b6 95%);
      color: #5ec264;
    }
    &.status-2 {
      background-image: linear-gradient(269deg, #ffdfcb, #ffb98d 95%);
      color: #ee7c26;
    }
    &.status-3 {
      background-image: linear-gradient(269deg, #a7c4ff, #729cf1);
      color: #4c79d5;
    }
    &.status-4 {
      background: linear-gradient(180deg, #d7d7d7 0, #f6f6f6 95%, #fff);
      color: #333;
    }
  }
  .on-lease, .status-1 {
    background-image: linear-gradient(90deg, #beed87, #d2e9b6 95%);
    color: #5ec264;
  }
  .off-lease, .status-2  {
    background-image: linear-gradient(269deg, #ffdfcb, #ffb98d 95%);
    color: #ee7c26;
  }
  .on-ground,.status-3 {
    background-image: linear-gradient(269deg, #a7c4ff, #729cf1);
    color: #4c79d5;
  }
  .writtenOff,.status-4 {
    background: linear-gradient(180deg, #d7d7d7 0, #f6f6f6 95%, #fff);
    color: #333;
  }
  .btn {
    &.btn-danger {
    }
    &.btn-normal {
    }
    &.btn-primary {
      padding: 6px 24px;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid #2670ca;
      background: #2f8cff;
      @include elementStyles(
        $size: 12px,
        $color: #fff,
        $fontFamily: $ibmplexRegular
      );
    }
    &.btn-secondary {
    }
  }
  .apps-title-cta {
    margin-bottom: 20px;
    h4 {
      @include elementStyles(
        $size: 20px,
        $color: $blackColor,
        $fontFamily: $ibmplexRegular
      );
      margin-bottom: 10px;
    }
    p {
      @include elementStyles(
        $size: 14px,
        $color: #8b8d91,
        $fontFamily: $ibmplexRegular
      );
    }
  }
  .apps-title-cta-list {
    ul {
      float: right;
      li {
        margin-left: 15px;
        &.revision-history {
          cursor: pointer;
          @include elementStyles(
            $size: 12px,
            $color: #2f8cff,
            $fontFamily: $ibmplexSemiBold
          );
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .mui-styled-table {
    &.hovered-table {
      tr {
        background: inherit;
        cursor: pointer;
      }
    }
    .actions-cell {
      text-align: right;
      svg {
        cursor: pointer;
      }
    }
    thead {
      background: #f0f2f3;
      th {
        span {
          @include elementStyles(
            $size: 13px,
            $color: #6d9bb9,
            $fontFamily: $ibmplexSemiBold
          );
          &.MuiTableSortLabel-root {
            &:hover {
              color: #496ac5;
              .MuiTableSortLabel-icon {
                color: #496ac5 !important;
              }
            }
            .MuiTableSortLabel-icon {
              opacity: 0.5 !important;
            }
          }
          &.MuiTableSortLabel-active {
            color: #496ac5;
            .MuiTableSortLabel-icon {
              color: #496ac5 !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background: #ebeff6;
        }
        &:nth-child(even) {
          background: #f5f7fa;
        }
        &.Mui-selected {
          background: rgba(245, 0, 87, 0.08);
        }
        td {
          word-break: break-word;
          padding: 9px !important;
        }
      }
    }
  }
  .label-value-card {
    p {
      @include elementStyles(
        $size: 14px,
        $color: #a0a0a0,
        $fontFamily: $ibmplexRegular
      );
      margin-bottom: 4px;
    }
    h6 {
      @include elementStyles(
        $size: 16px,
        $color: $blackColor,
        $fontFamily: $ibmplexRegular
      );
      word-break: break-word;
    }
  }
  .empty-collection-ui {
    padding: 20px 0;
    text-align: center;
    clear: both;
    img {
      width: 320px;
      margin-bottom: 10px;
    }
    h4 {
      @include elementStyles(
        $size: 18px,
        $color: $blackColor,
        $fontFamily: $ibmplexRegular,
        $margin: 0 0 10px 0
      );
    }
    p {
      @include elementStyles(
        $size: 14px,
        $color: #c4c4c4,
        $fontFamily: $ibmplexRegular
      );
      width: 540px;
      margin: 0 auto;
    }
  }
  .plt-filter-comp {
    padding: 10px 10px 6px 10px;
    border-radius: 0;
    min-height: 30px;
    position: relative;
    clear: both;
    .filter-icon {
      margin-right: 10px;
      position: relative;
      top: -4px;
    }
    .clear-all-filter {
      position: absolute;
      right: 10px;
      top: 8px;
      font-size: 12px;
      border: 1px solid #3f51b5;
      padding: 1px 4px;
      border-radius: 3px;
      cursor: pointer;
      color: #3f51b5;
    }
    .filter-applied {
      padding-right: 72px;
      ul {
        li {
          margin: 0 5px 5px 0;
          padding: 2px 5px 5px 10px;
          background: rgba(0, 78, 255, 0.2);
          border: 1px solid rgba(101, 83, 251, 0.35);
          border-radius: 14px;
          @include elementStyles(
            $size: 12px,
            $color: #c4c4c4,
            $fontFamily: $ibmplexRegular
          );
          .filter-label {
            color: #0b1f8a;
            text-decoration: underline;
            display: inline-block;
            margin-right: 8px;
          }
          .filter-data {
            color: $blackColor;
            font-family: $ibmplexSemiBold;
          }
          .remove-filter {
            cursor: pointer;
            padding-left: 8px;
            position: relative;
            top: 2px;
            svg {
              font-size: 16px;
              color: #ea6363;
            }
          }
        }
      }
    }
  }
  .plt-filter-footer {
    position: fixed;
    bottom: 0px;
    background: #fff;
    border-top: 1px solid #d7d7d7;
    z-index: 99;
    width: 100%;
    padding: 15px 30px;
    button {
      margin-right: 10px;
    }
  }
  .plt-filter-header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 16px 30px;
    border-bottom: 1px solid #d7d7d7;
    background: #e4e4e4;
    z-index: 99;
    h3 {
      @include elementStyles(
        $size: 18px,
        $color: #000000,
        $fontFamily: $ibmplexRegular
      );
    }
  }
  .plt-filter-body {
    padding: 60px 30px 80px 30px;
    width: 300px;
    .date-comp {
      margin-top: 15px;
      h4 {
        @include elementStyles(
          $size: 16px,
          $color: rgba(0, 0, 0, 0.54),
          $fontFamily: $ibmplexRegular
        );
      }
    }
  }
  .page-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: not-allowed;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100px;
      }
    }
  }
  .dropped-pic-card {
    width: 250px;
    height: 250px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .overlay,
    .dropped-picture-cta {
      display: none;
    }
    &:hover {
      .overlay,
      .dropped-picture-cta {
        display: block;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 8;
      background: rgba(0, 0, 0, 0.4);
    }
    .dropped-picture-cta {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 8;
      span {
        display: inline-block;
        padding: 4px 0;
        cursor: pointer;
        @include elementStyles(
          $size: 16px,
          $color: #ffffff,
          $fontFamily: $ibmplexRegular
        );
        text-shadow: 0 2px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .faqs-list{
    h2.title{
      @include elementStyles($size: 16px, $color: #bd10e0, $fontFamily: $ibmplexRegular, $margin:15px 0 10px 0);
    }
    .content{
      @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
    }
  }
  .whats-new-timeline{
    ul{
      li{
        padding: 15px 0;
        border-bottom: 1px solid #d7d7d7;
        span{
          @include elementStyles($size: 13px, $color: #c4c4c4, $fontFamily: $ibmplexLight);
        }
        h4{
          @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular, $margin:0 0 16px 0);
        }
        .content{

        }
      }
    }
  }
  // .image-gallery{
  //   .image-gallery-content{
  //     .image-gallery-thumbnails-wrapper{
  //       width: 800px !important;
  //       margin: 0 auto !important;
  //     }
  //     .image-gallery-thumbnail{
  //       width: 100px;
  //       height: 100px;
  //       overflow: hidden;
  //       .image-gallery-thumbnail-inner{
  //         img{
  //           width: 100px;
  //           height: 100px;
  //         }
  //       }
  //     }
  //   }
  // }
  .drag-drop-cnt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    p {
      @include elementStyles(
        $size: 20px,
        $color: #000000,
        $fontFamily: $ibmplexRegular
      );
    }
  }
  .delete-record-modal {
    text-align: center;
    padding: 30px;
    h4 {
      @include elementStyles(
        $size: 20px,
        $color: #000000,
        $fontFamily: $ibmplexRegular,
        $margin: 0 0 40px 0
      );
    }
    .actions-cta {
      button {
        margin-left: 10px;
        &.first-child {
          margin-left: 0;
        }
      }
    }
  }
  .edit-revision-history {
    margin-bottom: 15px;
    padding: 20px 20px 20px 30px;
    h6,
    p {
      font-size: 12px;
      margin-bottom: 5px;
    }
    ul {
      border-left: 1px solid #8b36e1;
      padding-left: 20px;

      li {
        position: relative;
        .timeline-icon {
          position: absolute;
          left: -35px;
          top: 0;
          z-index: 10;
          img {
            width: 27px;
            background: #ffffff;
          }
        }

        background: #fff;
        box-shadow: 0 4px 16px 0 #e6e6e6;
        border-radius: 6px;
        padding: 15px;
        margin-bottom: 20px;
        // overflow: hidden;
        table {
          width: 100%;
        }
      }
    }
  }
  .edit-detail-table {
    overflow: auto;
    table {
      width: 100%;
      border-collapse: separate;
      thead {
        th {
          padding: 4px 20px;
          font-family: $ibmplexRegular;
          font-size: 12px;
          color: #a0a0a0;
          border: 1px solid #ccc;
          width: 80px !important;
        }
      }
      tbody {
        th {
          padding: 4px 20px;
          font-family: $ibmplexRegular;
          font-size: 12px;
          color: #a0a0a0;
          border: 1px solid #ccc;
          width: 80px !important;
        }
        td {
          width: 80px !important;
          padding: 4px 20px;
          font-family: $ibmplexRegular;
          font-size: 12px;
          color: #000000;
          border: 1px solid #ccc;
          width: 80px !important;
          span {
            color: #000000 !important;
          }
        }
      }
    }
  }
  .termsHd {
    .auth-section {
      min-height: auto;
    }
    .termsAndCondition {
      padding: 30px 15px 60px;
      margin: 0 auto;
      background-color: #ffffff;
      h2 {
        font-family: $ibmplexSemiBold;
        font-size: 14px;
        color: #000;
        margin: 40px 0 10px;
        &.intro-heading {
          margin-top: 0;
        }
      }
      p,
      span {
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #7a7a7a;
      }
      li {
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #7a7a7a;
      }
    }
  }
}
.page-not-found{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .main{
    padding: 30px 40px;
    .MuiCardContent-root{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      img{
        width: 400px;
      }
      p{
        margin-top: 20px;
        font-size: 18px;
      }
    }
  }
}
.access-denied{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .main{
    padding: 30px 40px;
    .MuiCardContent-root{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      h2{
        font-weight: 400;
        text-transform: uppercase;
        color: #474141;
        margin-bottom: 10px
      }
    }
  }
}
.deployment-info{
  background: #fff1b6;
  padding: 5px;
  border: 1px solid #a78701;
  border-radius: 4px;
  margin-bottom: 10px;
  // display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  span{
    color: #a78701;
    font-size: 12px;
    font-family: $ibmplexSemiBold;
    h6{
      color:#478ddc;
      font-size: 12px;
      display: inline;
    }
  }
  img{
    float: right;
    cursor: pointer;
    color: #ff0000;
    margin-left: 5px;
  }
}

.maintenance-header-card{
   position: fixed;
   left: 70px;
   right: 0;
   background: #fff;
   z-index: 99;
   top: 40px;
   border-radius: 0;
   .asset-switcher{
     li{
       margin-right: 10px;
       a{
         font-size: 13px;
       }
       &:first-child {
         margin-left: 10px;
         a{
           svg {
             color: #6054e4;
           }
         }
       }
       &.module-type {
         width: 200px !important;
       }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .dropped-pic-card {
    .overlay,
    .dropped-picture-cta {
      display: inline-block !important;
    }
  }
  .apps-title-cta-list {
    margin-bottom: 20px;
    overflow: hidden;
  }
  .MuiDialog-paper {
    .MuiDialogActions-root {
      button {
        font-size: 11px;
      }
    }
  }
}
