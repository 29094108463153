@import 'variables';
body{
  .MuiDrawer-paper{
    // background: linear-gradient(0deg,#7c52e9,#2c92ff);
  }
  .desktop-py-sidenav{
    background: #6B5EB0;
    position: fixed;
  
    top: 41px;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 70px;
    text-align: center;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }
    nav{
      height: 446px;
      width: 100%;
      margin-top: 20px;
      overflow: auto;
      ul{
        padding: 0 4px 0 2px;
        li{
          position: relative;
          padding: 5px 0;

          a{
            display: block;
            width: 100%;
            margin: 0 3px 0 auto;
            padding: 10px 2px 6px 2px;
            &.active{
              background: rgba(0,0,0,.2);
              border-radius: 4px;
              padding: 9px 2px;
            }
            &:hover{
              background: rgba(0,0,0,.2);
              border-radius: 2px;
            }
            img{
              width: 20px;
              margin-right: 1px;
            }
            p{
              font-family: $ibmplexRegular;
              font-size: 12px;
              margin-top: 8px;
              color: #fff;
              display: block;
              position: relative;
              line-height: 11px;
              left: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .notification-menu-list{
    li.list-inline-item{
      margin-left: 20px;
      &:first-child{
        margin-left: 0;
      }
      img{
        width: 20px;
      }
    }
  }
  .desktop-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 5px 0;
    border-bottom: 1px solid #d7d7d7;
    background: #fff;
    z-index: 999;
    .app-switcher{
          @include elementStyles($size: $font16, $color: #bd10e0, $fontFamily: $ibmplexMedium);
        }
    .app-logo-search{
      .app-logo{
        a{
          padding: 0 17px;
          img{
            width: 32px;
          }
        }
      }
      .search-form{
        width: 80%;
        form{
          input{
            width: 100%;
            height: 52px;
            border: 0;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            background: #f3f6f9;
            padding-left: 20px;
            font-size: 18px;
            font-family: $ibmplexLight;
            &:focus{
              outline: none;
            }
          }
        }
      }
    }
    .header-right-nav{
      float: right;
      >.list-inline-item{
        margin-left: 1px;
        padding: 0px 15px;
        &:first-child{
          margin-left: 0;
          border-left: none;
        }
        &:last-child{
          border-left: 1px solid #ddd;
        }
        .user-menu-dropdown{
          button{
            img{
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            h3{
              font-size: 14px;
              line-height: 12px;
              text-transform: none;
            }
            span{
              font-size: 11px;
            }
          }

        }
      }
    }
  }
  .menu-items{
    color: #000000;
  }
  .mobile-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #d7d7d7;
    z-index: 100;
    ul{
      float: right;
      li{
        margin-left: 16px;
        a{
          img{
            width: 16px;
          }
        }
      }
    }
  }
  .mobile-main-navs{
    width: 250px;
    // padding: 10px;
    background: linear-gradient(0deg, #7c52e9, #2c92ff);
    height: 100%;
    overflow: auto;
    .user-info{
      padding: 10px;
      border-radius: 0;
      // margin-bottom: 10px;
      .user-card{
        .user-avatar{
          float: left;
          margin-right: 4px;
        }
        h3{
          @include elementStyles($size: $font16, $color: #000000, $fontFamily: $ibmplexRegular);
        }
        span{
          @include elementStyles($size: $font13, $color: #000000, $fontFamily: $ibmplexRegular);
        }
      }
    }
    .mobile-py-link{
      padding: 10px;
      background: transparent;
      ul{
        li{
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
          a{
            display: flex;
            align-items: center;
            cursor: pointer;

            img{
              width: 20px;
              margin-right: 10px;
            }
            p{
              display: inline;
              @include elementStyles($size: $font14, $color: #fff, $fontFamily: $ibmplexRegular);
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .secondary-sidenav{
    background: rgba(113, 99, 186, 0.9);
    width: 182px;
    position: fixed;
    left:70px;
    height: 100%;
    z-index: 99;
    padding: 10px 6px 6px;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { width: 0 !important }
    h4{
      padding: 10px 15px;
      margin: 8px 0;
      @include elementStyles($size: $font16, $color: #f0a4ff, $fontFamily: $ibmplexRegular);
    }
    ul{
      margin-top: 20px;
      li{
        a{
          display: block;
          padding-left: 32px;
          cursor: pointer;
          font-size: 16px !important;
          padding: 10px;
          @include elementStyles($size: $font14, $color: #fff, $fontFamily: $ibmplexRegular);
          &.active{
            background: #ffffff;
            color: #000000;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .mobile-secondary-drawer{
    padding: 5px 15px 2px 15px;
    background-image: linear-gradient(-180deg,#6538db,#0a56a8);
    color: #fff;
    svg{
      cursor: pointer;
    }
  }
  .mobile-secondary-manulist{
    h4{
      padding: 10px 15px;
      font-size: 16px;
      color: #f0a4ff;
      margin: 8px 0;
    }
    ul{
      li{
        a{
          display: block;
          padding-left: 32px;
          cursor: pointer;
          font-size: 12px;
          color: #fff;
          padding: 10px 15px;
          &:hover, &.active{
            background-image: linear-gradient(90deg,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,0));
          }
        }
      }
    }
  }
  .engine-sv-card{
    background: #fff;
    border: 1px solid #c6cddd;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    border-radius: 3px;
    margin-bottom: 15px;
    .tab-content{
      padding: 0 15px 15px;
      .text-label{
        font-family: $ibmplexRegular;
        font-size: 12px;
        color: #a0a0a0;
        display: inline-block;
        line-height: 16px;
      }
      .para-ui{
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #000;
      }
    }
  }
  footer{
    background: #191c26;
    padding: 50px 0;
    h4{
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      margin: 0 0 15px 0;
      clear: both;
    }
    a{
      font-size: 13px;
      color: #fff !important;
      font-weight: 400;
      display: inline-block;
    }
    ul.list-unstyled{
      li{
        a{
          color: #ffffff;
          padding: 8px 0;
          color: hsla(0,0%,100%,.8)!important;
        }
      }
    }
    ul.list-inline{
      margin-bottom: 15px;
      display: inline-block;
      li{
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .footer-copyright{
      margin-top: 20px;
      img{
        width: 120px;
        margin-bottom: 10px;
      }
      p{
        font-size: 13px;
        font-weight: 300;
        color: #fff;
      }
    }
  }
  .application-swicther-modal{
		.MuiDialog-paperWidthSm {
			max-width: 100%;
		}
	}
  .application-landing-page{
    .MuiTabs-scroller{
      margin-bottom: 0px;
    }
    h3{
        @include elementStyles($size: $font16, $color:#5666f9, $fontFamily: $ibmplexMedium, $weight:500, $margin:0 0 6px 0);
    }
    .application-card{
        padding: 20px;
        background: #7d7561;
        box-shadow: 0 2px 2px rgba(0,0,0,.25);
        border-radius: 4px;
        color: #fff;
        min-height: 225px;
        position: relative;
        &.no-access-app{
            opacity: 0.85;
        }
        cursor: pointer;
        .lock-icon{
            position: absolute;
            top: 10px;
            right: 20px;
            z-index: 10;
        }
        .app-title{
            margin-bottom: 15px;
            img{
                width: 50px;
                margin-right: 15px;
            }    
            h4{
                @include elementStyles($size: $font18, $fontFamily: $ibmplexMedium, $weight:500);
                sup{
                    color: #ab0202;
                }
            }
        }
        .app-desc{
            p{
                @include elementStyles($size: $font16, $fontFamily: $ibmplexRegular, $weight:400);
            }
        }
        button{
            position: absolute;
            bottom: 10px;
            left: 20px;
            z-index: 20;
            color: #fff;
            font-size: small
        };
        .no-access-content{
            position: absolute;
            bottom: 10px;
            left: 20px;
            z-index: 20;
            color: #fff03b;
            @include elementStyles($size: $font13, $fontFamily: $ibmplexRegular, $weight:400);
        }
    } 
  }

}
